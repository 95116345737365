import { createQuery, getCommunity, queryCommunities } from '@amityco/ts-sdk'
import { runQueryPromise } from './runQueryPromise'

export interface GetMyGroupParams {
  page?: Amity.Page
}

export const getMyGroups = async (
  params: GetMyGroupParams = {},
): Promise<{
  groups: Amity.Community[]
  nextPage?: Amity.Page
}> => {
  const query = createQuery(queryCommunities, {
    membership: 'member',
    isDeleted: false,
    sortBy: 'lastCreated',
    ...params,
  })

  const { data = [], nextPage } = await runQueryPromise(query)
  return { groups: data, nextPage }
}

export const getGroup = async (communityId: string): Promise<Amity.Community> => {
  const { data } = await getCommunity(communityId)
  return data
}
