import { ButtonHTMLAttributes, FC } from 'react'
import clsx from 'clsx'
import { Link, LinkProps } from 'ui/components/navigation'

// There are a lot of other features which can be smoothly incorporated:
// https://tailwindui.com/components/application-ui/elements/buttons

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
type Type =
  | 'primary'
  | 'secondary'
  | 'regular'
  | 'ghost'
  | 'disabled'
  | 'disabled-primary'
  | 'ghost-secondary'

type LinkAndButtonProps<T extends HTMLElement = HTMLElement> = LinkProps<T> &
  ButtonHTMLAttributes<HTMLButtonElement>

export interface ButtonProps<T extends HTMLElement = HTMLElement> extends LinkAndButtonProps<T> {
  size?: Size
  uiType?: Type
}

const SIZE_CLASSES_MAP: { [key in Size]: string } = {
  xs: 'px-2.5 py-1.5 text-xs h-7',
  sm: 'px-3 py-2 leading-4 text-sm h-8',
  md: 'px-4 py-2 leading-5 text-sm h-9',
  lg: 'px-4 py-3 leading-5 text-base h-11',
  xl: 'px-6 py-4 leading-5 text-base h-[52px]',
}

const TYPE_CLASSES_MAP: { [key in Type]: string } = {
  primary:
    'h-fit text-orange-50 border-transparent bg-orange-600 hover:bg-orange-700 focus:bg-orange-800',
  secondary:
    'h-fit text-orange-600 border-transparent bg-orange-50 hover:bg-orange-100 focus:bg-orange-200',
  regular:
    'h-fit shadow-sm text-deep-teal-800 border-taupe-600 bg-white hover:bg-deep-teal-50 hover:border-deep-teal-200 focus:bg-deep-teal-100 focus:border-deep-teal-300',

  ghost: 'h-fit border-transparent bg-transparent text-orange-400 hover:text-orange-200',

  disabled:
    'h-fit shadow-sm text-deep-gray-200 border-transparent bg-deep-gray-50 pointer-events-none',
  'disabled-primary':
    'shadow-sm text-deep-gray-200 border-transparent bg-deep-gray-50 pointer-events-none',
  'ghost-secondary':
    'h-fit border-transparent bg-transparent text-deep-teal-300 hover:text-deep-teal-500 underline',
}

const BASE_BUTTON_CLASSES = clsx(
  'items-center inline-block',
  'text-center',
  'border box-border rounded-md ',
  'font-medium',
  'focus:outline-none focus:ring-0',
)

export const Button: FC<ButtonProps> = ({
  size = 'md',
  uiType = 'primary',
  disabled,
  className,
  children,
  href,
  ...props
}) => {
  if (disabled) {
    uiType = uiType === 'primary' ? 'disabled-primary' : 'disabled'
  }

  const componentProps: LinkAndButtonProps = {
    disabled: disabled,
    className: clsx(
      BASE_BUTTON_CLASSES,
      SIZE_CLASSES_MAP[size],
      TYPE_CLASSES_MAP[uiType],
      className,
    ),
    ...props,
  }

  return (
    <>
      {href ? (
        <Link href={href} {...componentProps}>
          {children}
        </Link>
      ) : (
        <button {...componentProps}>{children}</button>
      )}
    </>
  )
}
