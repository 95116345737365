import { User } from 'api/dto'

export const getInitials = (user?: User): string => {
  return [user?.firstName, user?.lastName]
    .filter(Boolean)
    .map((name) => name.substring(0, 1)?.toUpperCase())
    .join('')
}

export function UserAvatar({ user }: { user?: User }) {
  const initials = getInitials(user)
  return (
    <div className="flex max-w-5xl items-center px-4 sm:px-6">
      <span className="bg-taupe-200 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full">
        <span className="font-brand text-deep-teal-500 text-sm font-medium lining-nums leading-none">
          {initials}
        </span>
      </span>

      <div className="ml-3">
        <div className="text-deep-teal-200 text-base font-medium leading-5">
          {user?.firstName} {user?.lastName}
        </div>
        <div className="text-deep-teal-200 text-sm font-medium leading-5">{user?.email}</div>
      </div>
    </div>
  )
}
