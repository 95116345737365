import {
  FC,
  KeyboardEventHandler,
  MouseEventHandler,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import { useForm } from 'react-hook-form'
import { Combobox, ComboboxOptionProps, ComboboxProps } from '@headlessui/react'
import { MagnifyingGlassIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { MapIcon } from '@heroicons/react/24/solid'
import { useAmity } from 'amity/lib'
import { searchUsers } from 'amity/lib'
import { SocialSearchMember } from 'api/dto'
import { axios } from 'api/lib'
import clsx from 'clsx'
import { LDFlag, useFlag } from 'launchdarkly'
import qs from 'qs'
import { Avatar } from 'ui/components/content'
import { cn, useOutsideClick } from 'ui/lib'
import { analytics } from 'ui/lib/analytics'
import { useDebouncedTrack } from 'ui/lib/analytics/useDebouncedTrack'
import { useRoutingContext } from 'ui/lib/navigation'
import { useScrollLock } from './useScrollLock'
import { Link } from '../Link'
import { useRouter } from '../useRouter'

const MIN_CHAR_COUNT = 3
const MAX_RESULTS = 5

interface SocialSearchProps
  extends Omit<ComboboxProps<Amity.User | null, true, false, 'div'>, 'nullable' | 'multiple'> {
  readonly isRightAlignedBeforeFocused?: boolean
  readonly isFocused?: boolean
  readonly setIsFocused?: (flag: boolean) => void
  readonly resultContainerRef?: MutableRefObject<HTMLElement>
  readonly optionsClassName?: string
}

export const SocialSearch: FC<SocialSearchProps> = ({
  className,
  optionsClassName,
  isRightAlignedBeforeFocused,
  isFocused,
  setIsFocused,
  resultContainerRef,
  ...rest
}) => {
  const usePerryDBForSearch = useFlag<boolean>(LDFlag.SocialSearchUsesDB)
  const enableMembersDiscoveryLink = useFlag<boolean>(LDFlag.EnableMemberDiscoveryNavigation)

  const router = useRouter()
  const { baseUrl } = useRoutingContext()
  const { isConnected } = useAmity()
  const [members, setMembers] = useState<SocialSearchMember[]>([])
  const [showNotEnoughLengthHint, setShowNotEnoughLengthHint] = useState(false)
  const [selected, setSelected] = useState<Amity.User | null>(null)
  const { register, setFocus, setValue, watch } = useForm({
    defaultValues: {
      search: router.query?.query ?? '',
    },
  })

  const { ref: searchRegisterRefCallback, ...restSearchRegisterProps } = register('search')
  const comboboxContainerRef = useRef<HTMLDivElement>(null)
  const searchInputRef = useRef<HTMLInputElement>(null)

  const search = watch('search') as string
  const setSearch = (search: string) => setValue('search', search)
  const focusSearchInput = () => setFocus('search')
  const blurSearchInput = () => searchInputRef.current?.blur()

  const isRightAligned = !isFocused && isRightAlignedBeforeFocused
  const isSearchEmpty = search?.length === 0
  const searchHasEnoughLength = search?.length >= MIN_CHAR_COUNT
  const searchHasLength = search?.length > 0
  const thereAreSearchResults = members.length > 0
  const thereIsNoResult = members.length === 0

  const handleGoToAllResults = () => {
    const baseSearchUrl = `${baseUrl}/search`
    const searchUrl = `${baseSearchUrl}?query=${search}`

    const isAlreadyOnSearchPage = router.pathname.startsWith(baseSearchUrl)

    if (isAlreadyOnSearchPage) {
      // Replace history for subsequent searches.
      // To keep single record in history for search page.
      // to prevent navigation back through entire search history.
      router.replace(searchUrl)
    } else {
      router.push(searchUrl)
    }

    // Close social search bar dropdown
    setIsFocused && setIsFocused(false)
  }

  const tryToGoToAllResults = () => {
    if (searchHasEnoughLength) {
      handleGoToAllResults()
    } else if (searchHasLength) {
      setShowNotEnoughLengthHint(true)
    } else {
      setShowNotEnoughLengthHint(false)
    }
  }

  const handleFocus = () => {
    setIsFocused && setIsFocused(true)
  }

  const handleBlur = useCallback(() => {
    setIsFocused && setIsFocused(false)

    // search finished tracking
    if (searchHasEnoughLength) {
      analytics?.track('Search for member', {
        search,
      })
    }
  }, [searchHasEnoughLength, search])

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        tryToGoToAllResults()
        event.stopPropagation()
        event.preventDefault()
      } else if (event.key === 'Escape') {
        blurSearchInput()
        handleBlur() // will set focused flag to false and move component to initial state
        event.stopPropagation()
        event.preventDefault() // default behavior is clear input and keep it focused, need prevent it
      } else if (event.key === 'Tab') {
        blurSearchInput()
        handleBlur() // will set focused flag to false and move component to initial state
        event.stopPropagation()
        event.preventDefault() // default behavior is clear input, select active item and move focus, need prevent it
      } else {
        setShowNotEnoughLengthHint(false)
      }
    },
    [handleBlur],
  )

  const handleMagnifierClick: MouseEventHandler<HTMLDivElement> = () => {
    if (isFocused) {
      tryToGoToAllResults()
    } else {
      // restore focus because we loose it after click outside input
      focusSearchInput()
    }
  }

  const handleClearSearch: MouseEventHandler<HTMLDivElement> = (event) => {
    setSearch('')
    setMembers([])
    if (isFocused) {
      // restore focus because we loose it after click outside input
      focusSearchInput()
    }
    event.stopPropagation()
    event.preventDefault()
  }

  useEffect(() => {
    if (selected) {
      setSearch('')
      setSelected(null)
      setMembers([])
      // There is race condition between headless ui combobox and our behavior
      // Combobox will restore focus after option click
      // We have to delay blur because it should not be focused
      window.setTimeout(() => {
        blurSearchInput()
        handleBlur()
      }, 100)
      router.push(`${baseUrl}/members/${selected.userId}`)
    }
  }, [selected, handleBlur])

  // As far portals used to render component it might have multiple containers
  const containerRefs: MutableRefObject<HTMLElement>[] = [comboboxContainerRef as any]
  if (resultContainerRef) {
    containerRefs.push(resultContainerRef)
  }
  useOutsideClick(containerRefs, handleBlur)

  useEffect(() => {
    if (searchHasEnoughLength && isFocused) {
      if (usePerryDBForSearch) {
        getMembersSocialSearch(search, MAX_RESULTS)
          .then((members) => setMembers(members))
          .catch(() => setMembers([]))
      } else {
        if (isConnected) {
          searchUsers({ displayName: search, page: { limit: MAX_RESULTS } })
            .then(({ users: members }) => setMembers(members.map(mapAmityUserToSocialSearchMember)))
            .catch(() => setMembers([]))
        }
      }
    } else if (members.length) {
      setMembers([])
    }
  }, [isConnected, search, searchHasEnoughLength, usePerryDBForSearch, isFocused])

  useScrollLock(isFocused!)

  const goToAllMembersResultsButton = (
    <li className="flex justify-between px-4 py-2 sm:px-6 md:block md:space-x-2">
      <span className="text-deep-teal-300 text-sm font-medium uppercase leading-5 tracking-wide">
        Members
      </span>
      <a
        className="cursor-pointer text-sm font-semibold leading-5 text-orange-600 hover:text-orange-800"
        onClick={handleGoToAllResults}
        data-trackclick={JSON.stringify({
          action: 'Viewed Search Results',
        })}
      >
        View all results
      </a>
    </li>
  )

  const noResultsLabel = (
    <li className={'leading-1 mx-4 mt-2 text-sm sm:mx-6 sm:mt-2'}>
      <p className={'text-deep-teal-500 mb-1 font-medium'}>Nothing has been found</p>
      {enableMembersDiscoveryLink && (
        <>
          <p className={'text-deep-teal-300 mb-1 font-normal'}>
            Not looking for a specific member, click to discover members.
          </p>
          <Link className={'font-semibold text-orange-600'} href={`${baseUrl}/discovery`}>
            {/* Preserve additional div for consistency with ionic router link */}
            <div className="flex items-center">
              <span className="mr-2">Member Discovery</span>
              <MapIcon className="h-4 w-4" />
            </div>
          </Link>
        </>
      )}
    </li>
  )

  const notEnoughSearchLength = (
    <li className={'text-deep-teal-500 mx-4 text-sm font-medium leading-5 sm:mx-6 sm:mt-2'}>
      Please enter 3 or more characters
    </li>
  )

  const socialSearchResult = isFocused && (searchHasEnoughLength || showNotEnoughLengthHint) && (
    <Combobox.Options
      className={cn(
        'pb-4 pt-4 md:space-y-2 md:pb-6', // box model
        'border-t-1 rounded-b-md bg-white', // styles
        'shadow-lg ring-1 ring-black ring-opacity-5', // shadow
        optionsClassName, // external custom styles
      )}
      as="ul"
      static
    >
      {searchHasEnoughLength && thereAreSearchResults && (
        <>
          {goToAllMembersResultsButton}
          {members.map((member) => (
            <MemberSearchOption key={member.userId} value={member} />
          ))}
        </>
      )}
      {searchHasEnoughLength && thereIsNoResult && noResultsLabel}
      {!searchHasEnoughLength && showNotEnoughLengthHint && notEnoughSearchLength}
    </Combobox.Options>
  )

  // search input tracking

  const debouncedTrack = useDebouncedTrack(2000)

  useEffect(() => {
    if (searchHasEnoughLength) {
      debouncedTrack('Search Input For Member', {
        search,
      })
    }
  }, [search, searchHasEnoughLength])

  return (
    <Combobox
      className={cn(
        'relative',
        'text-taupe-100 text-base font-normal leading-5 md:text-sm',
        'placeholder:text-taupe-200 placeholder:text-medium',
        'box-border rounded-md py-2 pl-8 md:pl-10',
        isRightAligned && 'pr-8 md:pr-10',
        className,
      )}
      as="form"
      data-testid="social-search-combobox"
      value={selected}
      nullable
      onChange={(value) => setSelected(value)}
      ref={comboboxContainerRef}
      {...rest}
    >
      <Combobox.Input
        autoComplete="off"
        className={cn(
          'hide-cross-icon w-full border-none bg-transparent p-0', // reset default styles
          'focus:outline-0 focus:ring-0', // reset default focus styles
          isRightAligned && 'text-right placeholder:text-right',
        )}
        type="search"
        placeholder="Search a member"
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        displayValue={() => search}
        ref={(ref) => {
          searchRegisterRefCallback(ref)
          ;(searchInputRef as any).current = ref
        }}
        {...restSearchRegisterProps}
      />

      <div
        className={clsx(
          'absolute inset-y-0 flex cursor-pointer items-center',
          isRightAligned ? 'right-0 md:pr-2' : 'left-0 md:pl-2',
        )}
        onClick={handleMagnifierClick}
        data-testid="magnifier-button"
      >
        <MagnifyingGlassIcon className="size-5 p-0" aria-hidden="true" />
      </div>

      {!isSearchEmpty && (
        <div
          onClick={handleClearSearch}
          className="absolute inset-y-0 right-0 flex cursor-pointer items-center md:pr-2"
          data-testid="clear-button"
        >
          {isFocused && <XCircleIcon className="h-5 w-5 text-orange-500" aria-hidden="true" />}
        </div>
      )}

      {resultContainerRef?.current ? (
        createPortal(socialSearchResult, resultContainerRef.current)
      ) : (
        <>
          {isFocused && (
            <div
              className="fixed bottom-0 left-0 right-0 top-16 z-40 overflow-hidden bg-black opacity-30 md:hidden"
              onClick={handleBlur}
              data-testid="social-search-overlay"
            />
          )}
          <div
            className={clsx(
              'fixed left-0 top-[65px] z-50 max-h-[418] w-full', // mobile position and sizes
              'md:absolute md:top-[50px]', // position and sizes
            )}
          >
            {socialSearchResult}
          </div>
        </>
      )}
    </Combobox>
  )
}

const MemberSearchOption: FC<ComboboxOptionProps<'li', SocialSearchMember>> = ({
  value,
  ...props
}) => {
  return (
    <Combobox.Option
      value={value}
      className={({ active }) =>
        clsx(
          'hover:bg-taupe-300 flex cursor-pointer select-none space-x-2.5 px-4 py-2.5 sm:px-6',
          active && 'sm:bg-taupe-300',
        )
      }
      {...props}
    >
      <Avatar
        className="h-10 w-10 md:h-6 md:w-6"
        src={
          value.avatarFileId
            ? `${process.env.NEXT_PUBLIC_AMITY_API_URL}/v3/files/${value.avatarFileId}/download`
            : ''
        }
      />
      <div className="md:flex md:items-center md:space-x-2.5">
        <div className="flex">
          <div className="text-deep-teal-500 sm:text-deep-teal-800 text-sm font-medium leading-5 sm:font-normal">
            {value.displayName}
          </div>
        </div>
        {value?.city && (
          <div className="flex items-center text-sm font-normal leading-5 md:space-x-2.5">
            <span className="text-deep-teal-100 hidden md:inline">&bull;</span>
            <span className="text-deep-teal-300">{value?.city}</span>
          </div>
        )}
      </div>
    </Combobox.Option>
  )
}

// [KB] Remove when we completely migrate to out DB as datasource
const mapAmityUserToSocialSearchMember = (amityUser: Amity.User): SocialSearchMember => ({
  userId: parseInt(amityUser.userId),
  displayName: amityUser.displayName!,
  avatarFileId: amityUser.avatarFileId!,
  headline: amityUser.metadata?.headline,
  city: amityUser.metadata?.city,
})

const getMembersSocialSearch = async (
  query: string,
  limit: number,
): Promise<SocialSearchMember[]> => {
  const {
    data: { members },
  } = await axios.get<{ members: SocialSearchMember[] }>(
    `/api/social-search/members?${qs.stringify({ query, limit })}`,
  )
  return members
}
