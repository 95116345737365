import { useEffect } from 'react'
import {
  createClient,
  connectClient,
  disconnectClient,
  enableCache,
  API_REGIONS,
} from '@amityco/ts-sdk'
import { axios } from 'api/lib'
import { useSession } from 'auth'
import { create } from 'zustand'

const client = createClient(process.env.NEXT_PUBLIC_AMITY_API_KEY!, API_REGIONS.US)
enableCache()

interface LoginParams {
  userId: string
  displayName?: string
}

interface AmityStore {
  userId?: string
  isConnecting: boolean
  isConnected: boolean
  client: Amity.Client
  error?: string
  login: (params: LoginParams) => Promise<void>
  logout: () => Promise<void>
}
export const useAmity = (): Omit<AmityStore, 'login'> & { activate: () => Promise<void> } => {
  const store = useAmityStore()
  const { user, isInitialized, socialProfileNotActivated } = useSession()
  useEffect(() => {
    const isCurrentUserAdmin = user?.roles.includes('ADMIN' as any)
    // admin can edit profiles of other users
    // that's fine that admin will have amity account with userId and hardcoded display name
    if (isCurrentUserAdmin) {
      _login(isInitialized, user?.id, 'meetperry admin')
    }
    // profile activated
    else if (!socialProfileNotActivated) {
      _login(isInitialized, user?.id)
    }
  }, [isInitialized, user?.id, JSON.stringify(user?.roles), socialProfileNotActivated])

  // creates user with userId only to login to the amity account
  // use only in onboarding flow
  // do not use anywhere else
  const activate = () => {
    return _login(isInitialized, user?.id)
  }

  const _login = async (isInitialized: boolean, userId?: number, displayName?: string) => {
    const amityStoreState = useAmityStore.getState()

    if (
      amityStoreState.isConnecting ||
      amityStoreState.isConnected ||
      amityStoreState.error ||
      !isInitialized ||
      !userId
    ) {
      return
    }

    const loginParams: LoginParams = {
      userId: `${userId}`,
    }

    if (displayName) {
      loginParams.displayName = displayName
    }

    return store.login(loginParams)
  }

  return { ...store, activate }
}

const useAmityStore = create<AmityStore>()((set) => ({
  client: client,
  error: '',
  isConnected: false,
  isConnecting: false,

  login: async ({ userId, displayName }: LoginParams) => {
    set({ error: '', isConnecting: true })

    try {
      const authToken = await getAuthenticationToken()
      const isConnected = await connectClient({ userId, authToken, displayName })

      set({ userId, isConnected })
    } catch (error) {
      const e = error as Error
      set({ error: e?.message || 'Connection failure. Something went wrong.' })
    } finally {
      set({ isConnecting: false })
    }
  },

  logout: async () => {
    try {
      await disconnectClient()
    } catch (error) {
      const e = error as Error
      set({ error: e?.message || 'Disconnection failure. Something went wrong.' })
    }
  },
}))

const getAuthenticationToken = async (): Promise<string> => {
  const { data } = await axios.get<string>('/api/amity/token')
  return data
}
