import {
  createQuery,
  queryUsers,
  getUser as getAmityUser,
  getUsers as getAmityUsers,
  updateUser as updateAmityUser,
} from '@amityco/ts-sdk'
import { omit } from 'lodash'
import { runQueryPromise } from './runQueryPromise'

export const getUser = async (userId: string): Promise<Amity.User> => {
  const query = createQuery(getAmityUser, userId)
  const { data } = await runQueryPromise(query)
  return data
}

export interface UserUpdateParams {
  userId: string
  displayName: string
  metadata?: {
    city?: string | null
    headline?: string | null
  }
  avatarFileId?: string
}
export const updateUser = async (params: UserUpdateParams): Promise<any> => {
  const query = createQuery(updateAmityUser, params.userId, omit(params, 'userId'))

  const { data } = await runQueryPromise(query)
  return data
}

export interface SearchUsersParams {
  displayName: string
  page?: Amity.Page
}
export interface SearchUsersResponse {
  users: Amity.User[]
  nextPage?: Amity.Page
}
export const searchUsers = async (params: SearchUsersParams): Promise<SearchUsersResponse> => {
  const query = createQuery(queryUsers, { ...params, sortBy: 'displayName' })

  const { data, nextPage } = await runQueryPromise(query)
  return { users: data!, nextPage }
}

export const getUsers = async (usersIds: string[]): Promise<Amity.User[]> => {
  const query = createQuery(getAmityUsers, usersIds)

  const { data } = await runQueryPromise(query)
  return data
}
