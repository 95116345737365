import { FC, Fragment, useEffect, useState } from 'react'
import { Menu, MenuProps, Transition } from '@headlessui/react'
import { UserIcon } from '@heroicons/react/24/outline'
import { useSession } from 'auth'
import clsx from 'clsx'
import { getInitials } from 'ui/src/components/toolbar'
import { NextLink } from '@perry/app/components/navigation'
import { useUserNavigation } from './userNavigation'

interface UserMenuProps extends MenuProps<'div'> {
  standalone?: boolean
}

const UserMenu: FC<UserMenuProps> = ({ className, standalone, ...props }) => {
  const { isInitialized, user } = useSession()
  const [initials, setInitials] = useState('')
  useEffect(() => {
    if (isInitialized && user) {
      setInitials(getInitials(user))
    }
  }, [isInitialized, user?.firstName, user?.lastName])

  const menu = useUserNavigation()

  return (
    <Menu as="div" className={clsx('relative', className)} {...props}>
      {({ open }) => (
        <>
          <div>
            <Menu.Button
              className="bg-taupe-200 flex max-w-xs items-center rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
              data-cy="user-menu-button"
            >
              <span className="sr-only">Open user menu</span>
              <span className="flex h-8 w-8 items-center justify-center rounded-full">
                {initials ? (
                  <span className="font-brand text-deep-teal-500 text-sm font-medium lining-nums leading-none">
                    {initials}
                  </span>
                ) : (
                  <UserIcon data-testid="user-icon" className="text-deep-teal-500 h-5 w-5" />
                )}
              </span>
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={clsx(
                'absolute right-0 z-10 w-48 bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                standalone ? 'rounded' : 'mt-4 origin-top-right rounded-b',
              )}
              static
            >
              {menu.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <NextLink
                      className={clsx(
                        'block w-full whitespace-nowrap border-l-4 p-2 pl-3 pr-4',
                        'text-sm leading-5',
                        !active && !item.current && 'text-deep-teal-800 border-transparent',
                        active && !item.current && 'bg-taupe-300',
                        active && 'text-text-deep-teal-800  border-transparent',
                        item.current && 'border-orange-500 bg-orange-50 text-orange-800',
                      )}
                      href={item.href}
                      onClick={item.onClick}
                      target={item.target}
                    >
                      {item.name}
                    </NextLink>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

export default UserMenu
