import { FC, HTMLAttributes } from 'react'
import clsx from 'clsx'
import { TopAnnouncementBanner, useTopAnnouncementBanner } from 'ui/components/content'
import { cn } from 'ui/lib'
import { Metadata } from '../../metadata'
import { Footer } from '../Footer'
import {
  SmsReceivingConsentModal,
  useIsSmsReceivingConsentModalAllowed,
} from '../SmsReceivingConsentModal'
import { AppToolbar } from '../Toolbar'

export interface MainLayoutProps extends HTMLAttributes<HTMLDivElement> {
  readonly title?: string
  readonly hideToolbar?: boolean
  readonly Toolbar?: FC
  readonly banner?: JSX.Element
  readonly hideAnnouncementBanner?: boolean
  readonly bgColorClass?: string
}

export const MainLayout: FC<MainLayoutProps> = ({
  className,
  children,
  title,
  hideToolbar,
  banner,
  Toolbar,
  hideAnnouncementBanner,
  bgColorClass,
  ...props
}) => {
  const isSmsReceivingConsentModalAllowed = useIsSmsReceivingConsentModalAllowed()

  return (
    <>
      <Metadata title={title} />
      <div className={cn("flex h-full min-h-full flex-col", bgColorClass)}>
        {!hideToolbar && (Toolbar ? <Toolbar /> : <AppToolbar />)}
        {!hideAnnouncementBanner && <DesktopTopAnnouncementBanner />}
        {banner}
        {isSmsReceivingConsentModalAllowed && <SmsReceivingConsentModal />}
        <div className={clsx(`flex-1`, className)} {...props}>
          {children}
        </div>
        <Footer />
      </div>
    </>
  )
}

function DesktopTopAnnouncementBanner() {
  const topAnnouncementBanner = useTopAnnouncementBanner()
  return <TopAnnouncementBanner {...topAnnouncementBanner} />
}
