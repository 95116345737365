import { FC, MouseEventHandler, useCallback } from 'react'
import { Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { TopAnnouncementBanner as TopAnnouncementBannerDTO } from 'api/cms'
import clsx from 'clsx'
import { analytics } from 'ui/lib/analytics'
import { Button } from 'ui/src/components/forms'
import { Link } from '../../navigation'

interface TopAnnouncementBannerProps {
  shown: boolean
  banner?: TopAnnouncementBannerDTO
  dismiss: () => void
}

export const TopAnnouncementBanner: FC<TopAnnouncementBannerProps> = ({
  shown,
  banner,
  dismiss,
}) => {
  const isExternal = banner?.url.startsWith('http://') || banner?.url.startsWith('https://')
  const target = isExternal ? '_blank' : undefined

  const onDismissHandler: MouseEventHandler = useCallback(
    (event) => {
      event.stopPropagation()
      event.preventDefault()
      analytics?.track('Thin Banner Dismissed')
      dismiss()
    },
    [dismiss],
  )

  return (
    <Transition
      show={shown}
      leave="transition-all ease-out duration-200 overflow-hidden"
      leaveFrom="max-h-32"
      leaveTo="max-h-0"
    >
      <Link
        className={clsx(
          'bg-top-banner-left from-top-banner-left to-top-banner-right flex items-center justify-center bg-gradient-to-r',
        )}
        href={banner?.url}
        target={target}
        data-trackclick={JSON.stringify({
          action: 'Thin Banner Clicked',
        })}
      >
        <div className="flex w-full max-w-7xl items-center justify-between px-8 sm:px-6 lg:px-8">
          <p className="shrink grow py-4 text-center leading-6 text-white">{banner?.content}</p>
          <Button
            uiType="ghost"
            className="-mr-4 shrink-0 grow-0 lg:-mr-4"
            onClick={onDismissHandler}
          >
            <XMarkIcon className="text-deep-teal-100 h-4 w-4" />
          </Button>
        </div>
      </Link>
    </Transition>
  )
}
