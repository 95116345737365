import { FC } from 'react'
import Link from 'next/link'
import { useSession } from 'auth'
import clsx from 'clsx'
import { UserAvatar } from 'ui/src/components/toolbar'
import { useUserNavigation } from './userNavigation'

const UserMenuPopup: FC = () => {
  const { user } = useSession()
  const userNavigation = useUserNavigation()

  return (
    <>
      <UserAvatar user={user} />

      <div className="mx-auto mt-3 max-w-5xl space-y-1 sm:px-4">
        {userNavigation.map((item) => (
          <Link
            key={item.name}
            href={item.href}
            onClick={item.onClick}
            className={clsx(
              'relative block px-4 py-2.5 text-base font-medium  sm:px-6 ',
              item.current
                ? clsx(
                    'bg-deep-teal-600 text-white',
                    'before:absolute before:inset-0 before:block before:w-1 before:bg-orange-500',
                  )
                : 'text-deep-teal-100 hover:bg-deep-teal-600 hover:text-white',
            )}
          >
            {item.name}
          </Link>
        ))}
      </div>
    </>
  )
}
export default UserMenuPopup
