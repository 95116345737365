import { ComponentProps, Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import Link from 'next/link'
import { PlusIcon } from '@heroicons/react/24/solid'
import { Role } from 'api/dto'
import { useSession } from 'auth/src/useSession'
import clsx from 'clsx'
import { LDFlag, useFlag } from 'launchdarkly'
import { SocialSearch } from 'ui/components/navigation/SocialSearch'
import { Button } from 'ui/src/components/forms'
import { SmallLogo } from 'ui/src/components/toolbar'
import {
  BaseToolbar,
  BaseToolbarContentProps,
  Logo,
  Nav,
  NavigationItem,
  PopoverMenu,
  PopoverMenuButton,
} from './BaseToolbar'
import { UserMenu, UserMenuPopup } from './UserMenu'

const adminGlobalNav: NavigationItem[] = [
  {
    name: 'Home',
    href: '/admin',
  },
]

const investorGlobalNav: NavigationItem[] = [
  {
    name: 'Home',
    href: '/',
    regex: /^\/$/,
  },
  {
    name: 'The Club',
    children: [
      {
        name: 'Articles',
        href: '/articles',
      },
      {
        name: 'Events',
        href: '/events',
      },
      {
        name: 'Groups',
        href: '/groups',
      },
    ],
  },
  {
    name: 'Alternatives',
    children: [
      {
        name: 'Investment Opportunities',
        href: '/investments',
      },
      {
        name: 'Portfolio',
        href: '/portfolio',
      },
      {
        name: 'Data Rooms',
        href: 'https://dataroom.anduin.app/#/dashboard/all/active',
        target: '_blank',
      },
    ],
  },
]

const wealthChannelsNav: NavigationItem[] = [
  {
    name: 'Wealth Channels',
    announcement: 'NEW',
    children: [
      {
        name: 'Insurance',
        href: '/wealth-channels/insurance',
      },
    ],
  },
]

export const AppToolbar: FC<ComponentProps<'div'>> = (props) => {
  const [navigation, setNavigation] = useState([])
  const { user, isInitialized } = useSession()
  const showWealthChannels = useFlag(LDFlag.WealthChannels)

  useEffect(() => {
    setNavigation([
      ...(user?.roles?.includes(Role.ADMIN) ? adminGlobalNav : []),
      ...(user?.roles?.includes(Role.INVESTOR) ? investorGlobalNav : []),
      ...(user?.roles?.includes(Role.INVESTOR) && showWealthChannels ? wealthChannelsNav : []),
    ])
  }, [user, isInitialized, showWealthChannels])

  return (
    <BaseToolbar
      {...props}
      content={(props) => <AppToolbarContent navigation={navigation} {...props} />}
      popoverContent={() => (
        <PopoverMenu navigation={navigation}>
          <UserMenuPopup />
        </PopoverMenu>
      )}
    />
  )
}

export default AppToolbar

interface AppBaseToolbarContentProps extends BaseToolbarContentProps {
  navigation: NavigationItem[]
}

const AppToolbarContent: FC<AppBaseToolbarContentProps> = ({ open, navigation }) => {
  const [isSocialSearchFocused, setIsSocialSearchFocused] = useState(false)

  return (
    <>
      {!isSocialSearchFocused && (
        <>
          <Logo className="hidden shrink-0 lg:block" link="/" />
          <Link href="/" className="block shrink-0 lg:hidden">
            <SmallLogo />
          </Link>
          <Nav className="ml-8 hidden shrink-0 md:flex" navigation={navigation} />
          <div className="flex-1" />
        </>
      )}

      <ToolbarSocialSearchWithLD
        isSocialSearchFocused={isSocialSearchFocused}
        setIsSocialSearchFocused={setIsSocialSearchFocused}
      />

      {!isSocialSearchFocused && (
        <>
          <Button
            className="mr-4 hidden shrink-0 flex-nowrap items-center gap-2 md:flex"
            uiType="ghost"
            href="/nominations"
            data-trackclick={JSON.stringify({ action: 'Clicked Nominate Member - Topnav' })}
          >
            <PlusIcon className="h-4 w-4" />
            <span className="whitespace-nowrap">Nominate a member</span>
          </Button>
          <UserMenu className="hidden md:block" />
          <PopoverMenuButton className="md:hidden" open={open} />
        </>
      )}
    </>
  )
}

interface ToolbarSocialSearchWithLDProps extends ComponentProps<'div'> {
  isSocialSearchFocused: boolean
  setIsSocialSearchFocused: Dispatch<SetStateAction<boolean>>
}

export const ToolbarSocialSearchWithLD: FC<ToolbarSocialSearchWithLDProps> = ({
  isSocialSearchFocused,
  setIsSocialSearchFocused,
}) => {
  const { user, isInitialized } = useSession()
  const [isAvailableForCurrentUser, setIsAvailableForCurrentUser] = useState(false)

  useEffect(() => {
    setIsAvailableForCurrentUser(user?.roles?.includes(Role.INVESTOR))
  }, [user, isInitialized])

  return isAvailableForCurrentUser ? (
    <div
      className={clsx(
        'flex w-full flex-initial justify-center',
        isSocialSearchFocused ? 'mx-0 max-w-full' : 'mx-6 md:mx-4 md:max-w-[180px]',
      )}
    >
      <SocialSearch
        className={clsx('w-full md:max-w-2xl')}
        isFocused={isSocialSearchFocused}
        setIsFocused={setIsSocialSearchFocused}
      />
    </div>
  ) : null
}
