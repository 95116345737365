import { useEffect, useState } from 'react'
import { getMyGroups } from './group-service'
import { useAmity } from './useAmity'

export const useMyGroups = (limit: number) => {
  const [groups, setGroups] = useState<Amity.Community[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const { isConnected } = useAmity()

  useEffect(() => {
    if (!isConnected) {
      return
    }

    getMyGroups({ page: { limit } }).then(({ groups }) => {
      setGroups(groups)
      setIsLoading(false)
    })
  }, [isConnected, limit, setGroups, setIsLoading])

  return { groups, isLoading }
}
